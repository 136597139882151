/* eslint-disable no-undefined */
import { EyeOutlined, TrophyOutlined, UserOutlined } from '@ant-design/icons';
import { Col, Divider, Flex, Row } from 'antd';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AwardFailSvg from '../../assets/awardfail.svg';

import { CancelMessage } from './Components/CancelMessage';
import { CardStatus } from './Components/CardStatus';
import { TermsAndConditions } from './Components/TermsAndConditions';
import { TermsConditionsContentTitle } from './Components/TermsAndConditions/styles';
import * as S from './styles';
import { useBidSupplierView } from './useBidSupplierView';

import ExclamationCircle from '../../assets/exclamation-circle.svg';
import CardCountDown from '../../components/Common/CardCountdown';
import { Confetti } from '../../components/Common/Confetti';
import StyledBidCardList from '../../components/Common/StyledBidCardList';
import StyledButton from '../../components/Common/StyledButton';
import { StyledLoading } from '../../components/Common/StyledLoading';
import StyledModal from '../../components/Common/StyledModal';
import StyledSelect from '../../components/Common/StyledSelect';
import { BidStatus } from '../../helpers/enums';
import { handleFormatValueFields } from '../../helpers/nUtils';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { QuotationCard } from './Components/QuotationCard';
import { QuotationFilesModal } from './Components/QuotationFilesModal';
import { QuotationUploadModal } from './Components/QuotationUploadModal';

export function BidSupplierView() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.rfq.title'));
  const {
    steps,
    bidData,
    bidOptions,
    idBid,
    loading,
    termsConditon,
    termsConditionData,
    openModal,
    firstAccess,
    termsConditionDetail,
    acceptedTerms,
    acceptTerms,
    denyTerms,
    getSupplierUserName,
    fetchStatus,
    getBidId,
    fetchData,
    setIdBid,
    setOpenModal,
    Review,
    openUploadModal,
    openUploadedFilesModal,
    setOpenUploadModal,
    setOpenUploadedFilesModal,
    fileList,
    handleUploadFile,
    removeFile,
    clearFiles,
    sendFiles,
    uploadedFileList,
    uploadProgress,
    loadingUpdate,
    fetchUploadedFiles,
    fileIdDelete,
    isAwardModalOpen,
    setIsAwardModalOpen,
    awardData,
    fecthAwards,
    acceptAwardMessage,
    isLoadingMessage,
    setAceptedTerms,
    setTermsConditon,
    setAwardData,
  } = useBidSupplierView();
  const history = useHistory();

  useEffect(() => {
    getBidId();
  }, []);

  useEffect(() => {
    fetchData();
    fetchStatus();
  }, [idBid]);

  useEffect(() => {
    return () => {
      window.history.replaceState({}, '');
    };
  }, []);

  return (
    <>
      {isAwardModalOpen && <Confetti />}
      <StyledModal
        key="modal-awardMessage"
        title={t('pages.bidSupplierRFQ.result.modal.title')}
        open={isAwardModalOpen}
        body={
          <Flex style={{ paddingBottom: '1.5rem' }} vertical>
            <Divider orientation="left" orientationMargin={0}>
              <b>{t('pages.bidSupplierRFQ.result.modal.description')}</b>
            </Divider>
            {awardData?.message}
          </Flex>
        }
        width="30rem"
        centered
        footer={
          <Flex justify="flex-end">
            <StyledButton variant="primary" onClick={acceptAwardMessage} loading={isLoadingMessage}>
              {t('pages.bidSupplierRFQ.result.modal.gotIt')}
            </StyledButton>
          </Flex>
        }
      />
      <StyledModal
        key="modal-newcomponent"
        title="Terms Condition"
        open={openModal}
        onClose={() => setOpenModal(false)}
        footer={
          <StyledButton variant="secondary" onClick={() => setOpenModal(false)}>
            {t('common.close')}
          </StyledButton>
        }
        body={
          <>
            <Flex align="center" justify="space-between">
              <TermsConditionsContentTitle>{bidData.rfq}</TermsConditionsContentTitle>
              <Flex align="center" gap={16}>
                {termsConditionDetail?.acceptDate && (
                  <CardStatus
                    status="accepted"
                    time={dayjs(termsConditionDetail.acceptDate).format('MM.DD.YYYY - hh:mma')}
                    user={`${termsConditionDetail?.userAccept} - ${termsConditionDetail?.supplier}`}
                  />
                )}
                {termsConditionDetail?.rejectDate && (
                  <CardStatus
                    status="denied"
                    time={dayjs(termsConditionDetail.rejectDate).format('MM.DD.YYYY - hh:mma')}
                    user={`${termsConditionDetail.userReject} - ${termsConditionDetail.supplier}`}
                  />
                )}
              </Flex>
            </Flex>
            <S.ModalDivider />
            <div
              dangerouslySetInnerHTML={{
                __html: termsConditionData,
              }}
            />
          </>
        }
        width="64rem"
      />
      <StyledModal
        key="uploadQuotationFile"
        body={
          <QuotationUploadModal
            loading={loadingUpdate}
            uploadProgress={uploadProgress}
            handleChange={handleUploadFile}
            removeFile={removeFile}
            fileList={fileList}
            fileIdDelete={fileIdDelete}
            uploadedFiles={uploadedFileList}
          />
        }
        footer={
          <Flex justify="space-between">
            <StyledButton
              variant="slim"
              disabled={loadingUpdate}
              onClick={() => {
                clearFiles();
                setOpenUploadModal(false);
              }}
            >
              {t('common.cancel')}
            </StyledButton>
            <StyledButton variant="primary" onClick={() => sendFiles()} disabled={loadingUpdate}>
              {t('common.send')}
            </StyledButton>
          </Flex>
        }
        onClose={() => {
          clearFiles();
          setOpenUploadModal(false);
        }}
        title={t('pages.bidSupplierRFQ.quotation.modal.title')}
        centered
        open={openUploadModal}
        width="34rem"
      />
      <StyledModal
        key="uploadedQuotation"
        body={
          <QuotationFilesModal
            uploadedFiles={uploadedFileList}
            removeFile={removeFile}
            fileIdDelete={fileIdDelete}
          />
        }
        footer={null}
        onClose={() => {
          setOpenUploadedFilesModal(false);
        }}
        title={t('pages.bidSupplierRFQ.quotation.modal.title')}
        centered
        open={openUploadedFilesModal}
        width="34rem"
      />
      <Flex justify="space-between" align="center">
        <Col>
          <Row align="middle" style={{ gap: 4 }}>
            <S.SpanTile>{t('pages.bidSupplierRFQ.Title')}</S.SpanTile>
            <S.BidComponents>| {bidData.rfq}</S.BidComponents>
          </Row>
        </Col>
        <Col>
          <Row align="middle" style={{ gap: 4 }}>
            <S.SupplierSpan>{getSupplierUserName()?.supplier || '-'}</S.SupplierSpan>
            <S.UserContainer>
              <UserOutlined />
              <span>{getSupplierUserName()?.user || '-'}</span>
            </S.UserContainer>
          </Row>
        </Col>
      </Flex>
      <Flex justify="flex-end" align="center">
        <StyledSelect
          label="BID"
          options={bidOptions}
          value={idBid || ''}
          onChange={(value: string) => {
            setIdBid(value);
            setAceptedTerms(null);
            setTermsConditon(undefined);
            setAwardData(undefined);
          }}
          styles={{ width: '17.5rem' }}
        />
      </Flex>
      {loading ? (
        <StyledLoading height={10} />
      ) : (
        <S.StepsContainer noBorder>
          <S.StepsComponent items={steps} />
        </S.StepsContainer>
      )}

      {loading ? (
        <StyledLoading height={10} />
      ) : steps.some((step) => step.key === BidStatus.CANCELED) ? (
        <CancelMessage bidName={bidData.rfq} />
      ) : !termsConditon ? (
        <TermsAndConditions
          title={bidData.rfq}
          body={termsConditionData}
          accept={acceptedTerms}
          firstAccess={firstAccess}
          status={steps.find((item) => item.title === 'Round Open')?.status === 'finish'}
          details={termsConditionDetail}
          review={Review}
          accepted={acceptTerms}
          deny={denyTerms}
        />
      ) : steps.find((step) => step.key === BidStatus.RESULT)?.status === 'finish' && !awardData ? (
        <S.AwardFailResult>
          <S.AwardFailResultTitle>
            {t('pages.bidSupplierRFQ.result.awardFail.title')}
          </S.AwardFailResultTitle>
          <S.AwardFailResultContent>
            <img src={AwardFailSvg} alt="Award Fail" />
            <span>{t('pages.bidSupplierRFQ.result.awardFail.description')}</span>
          </S.AwardFailResultContent>
        </S.AwardFailResult>
      ) : (
        <S.ContentContainer>
          {steps.find((step) => step.key === BidStatus.RESULT)?.status === 'finish' && awardData ? (
            <S.ContentLeftContainer>
              <S.HeaderContentLeft>
                <Flex gap={8}>
                  <TrophyOutlined className="awardIcon" />
                  <S.TitleCard>{t('pages.bidSupplierRFQ.result.award')}</S.TitleCard>
                </Flex>
                <StyledButton
                  variant="primary"
                  onClick={() => {
                    history.push('/award-list', { bidId: idBid, bidName: bidData.rfq });
                  }}
                  icon={<EyeOutlined />}
                >
                  {t('pages.bidSupplierRFQ.result.seeDetails')}
                </StyledButton>
              </S.HeaderContentLeft>
              <S.CardsBidContainer>
                {StyledBidCardList(awardData.awardItens, true)}
              </S.CardsBidContainer>
            </S.ContentLeftContainer>
          ) : (
            <S.ContentLeftContainer>
              <S.HeaderContentLeft>
                <S.TitleCard>{t('pages.bidSupplierRFQ.itemsTitle')}</S.TitleCard>
                <StyledButton
                  variant="primary"
                  disabled={
                    steps.find((item) => item.key === BidStatus.ROUND_OPEN)?.status !== 'finish' ||
                    steps.find((item) => item.key === BidStatus.CLOSED)?.status === 'finish'
                  }
                  onClick={() => {
                    history.push(`/bid-response?bidId=${idBid}`);
                  }}
                >
                  {t('pages.bidSupplierRFQ.editBid')}
                </StyledButton>
              </S.HeaderContentLeft>
              <S.CardsBidContainer>{StyledBidCardList(bidData.itemsQuotation)}</S.CardsBidContainer>
            </S.ContentLeftContainer>
          )}
          <S.ContentRightContainer>
            <S.HeaderContentRight>
              <S.TitleCard>{t('pages.bidSupplierRFQ.roundDetails')}</S.TitleCard>
            </S.HeaderContentRight>
            <S.Padding>
              <Flex justify="space-between" gap={16}>
                {bidData.round && bidData.round > 0 ? (
                  <S.RoundContenteContainer style={{ maxWidth: '8rem' }}>
                    <Flex vertical>
                      <S.PotentialLabel>{t('pages.bidSupplierRFQ.round')}</S.PotentialLabel>
                      <S.PotentialValue>{bidData.round}</S.PotentialValue>
                    </Flex>
                  </S.RoundContenteContainer>
                ) : null}
                <S.RoundContenteContainer>
                  <Flex vertical>
                    <S.PotentialLabel>
                      {t('pages.bidSupplierRFQ.potentialRevenue')}
                    </S.PotentialLabel>
                    <S.PotentialValue>
                      $
                      {bidData.potentialRevenue
                        ? ` ${handleFormatValueFields({
                            number: bidData.potentialRevenue,
                          })}`
                        : ' -'}
                    </S.PotentialValue>
                  </Flex>
                </S.RoundContenteContainer>
              </Flex>
              <S.RoundContenteContainer>
                {bidData.respondDate && (
                  <CardCountDown
                    onFinished={fetchStatus}
                    showTime
                    endDate={bidData.respondDate}
                    stop={steps.find((item) => item.title === 'Round Open')?.status !== 'finish'}
                  />
                )}
              </S.RoundContenteContainer>
              <S.TermsConditionContainer>
                <Flex>
                  <S.AuditIcon />
                  <S.TermsConditionsButton variant="slim" onClick={() => setOpenModal(true)}>
                    {t('pages.bidSupplierRFQ.termsAndConditions.title')}
                  </S.TermsConditionsButton>
                </Flex>
                <img src={ExclamationCircle} alt="icon" />
              </S.TermsConditionContainer>
              {steps.find((step) => step.key === BidStatus.RESULT)?.status === 'finish' &&
                awardData && (
                  <QuotationCard
                    hasFile={uploadedFileList.length > 0}
                    onClickButton={() => setOpenUploadModal(true)}
                    onClickTitle={() => setOpenUploadedFilesModal(true)}
                  />
                )}
            </S.Padding>
          </S.ContentRightContainer>
        </S.ContentContainer>
      )}
    </>
  );
}
