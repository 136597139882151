import { useState } from 'react';
import theme from '../../../styles/theme';

export function useCardCountDown() {
  const [color, setColor] = useState<string>(theme.colorsDesignSystem.grayAcer);
  const twoDays = 172800000;
  const oneDay = 86400000;

  const statusUrgency = (milliseconds: any) => {
    if (milliseconds > twoDays && color !== theme.colorsDesignSystem.primary)
      return setColor(theme.colorsDesignSystem.primary);

    if (
      milliseconds < twoDays &&
      milliseconds >= oneDay &&
      color !== theme.colorsDesignSystem.tagColorYellow
    )
      return setColor(theme.colorsDesignSystem.tagColorYellow);

    if (milliseconds < oneDay && color !== theme.colorsDesignSystem.red)
      return setColor(theme.colorsDesignSystem.red);

    if (milliseconds <= 0 && color !== theme.colorsDesignSystem.grayAcer)
      return setColor(theme.colorsDesignSystem.red);

    return setColor(theme.colorsDesignSystem.grayAcer);
  };

  return {
    statusUrgency,
    color,
  };
}
