/* eslint-disable no-undefined */
import {
  FlagOutlined,
  FolderViewOutlined,
  LockOutlined,
  SendOutlined,
  TrophyOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { AxiosResponse, HttpStatusCode } from 'axios';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UploadFile, UploadProps } from 'antd';
import { UploadFileStatus } from 'antd/es/upload/interface';
import { useHistory, useLocation } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import { StyledSelectOptions } from '../../components/Common/StyledSelect/types';
import { BidStatus } from '../../helpers/enums';
import { getSupplierUserName, oneAlert } from '../../helpers/nUtils';
import { getURI } from '../../helpers/utils';
import { api } from '../../services/api';
import { services } from '../../services/services';
import * as S from './styles';
import {
  BidSupplierViewData,
  IExtendedUploadFiles,
  IProcessPercent,
  IReceiveAward,
  ITimeLine,
  IUploadedFiles,
  LoadingVariablesStatus,
  StepsStatus,
  TermsConditionsData,
  TermsConditionsDetailData,
} from './types';

export const useBidSupplierView = () => {
  const initialSteps: StepsStatus[] = [
    {
      title: 'Open Bid',
      status: 'wait',
      icon: <UnlockOutlined />,
      key: BidStatus.OPEN,
    },
    {
      title: 'Round Open',
      status: 'wait',
      icon: <FlagOutlined />,
      key: BidStatus.ROUND_OPEN,
    },
    {
      title: 'Quotation Sent',
      status: 'wait',
      icon: <SendOutlined />,
      key: BidStatus.QUOTATION_SENT,
    },
    {
      title: 'Bid Closed',
      status: 'wait',
      icon: <LockOutlined />,
      key: BidStatus.CLOSED,
    },
    {
      title: 'In Review',
      status: 'wait',
      icon: <FolderViewOutlined />,
      key: BidStatus.IN_REVIEW,
    },
    {
      title: 'Result',
      status: 'wait',
      icon: <TrophyOutlined />,
      key: BidStatus.RESULT,
    },
  ];

  const { t } = useTranslation();
  const history = useHistory();
  const { pathname, state } = useLocation<{
    bidId: string;
  }>();

  const [idBid, setIdBid] = useState<string>('');
  const [bidOptions, setBidOption] = useState<StyledSelectOptions[]>([]);
  const [loading, setIsLoading] = useState<boolean>(true);
  const [steps, setSteps] = useState<Array<StepsStatus>>(initialSteps);
  const [bidData, setBidData] = useState<BidSupplierViewData>({
    rfq: '',
    supplier: '',
    user: '',
    potentialRevenue: '',
    respondDate: '',
    itemsQuotation: [],
  });
  const [awardData, setAwardData] = useState<IReceiveAward>();

  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);
  const [openUploadedFilesModal, setOpenUploadedFilesModal] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isAwardModalOpen, setIsAwardModalOpen] = useState<boolean>(false);
  const [isLoadingMessage, setIsLoadingMessage] = useState<boolean>(false);
  const [messageId, setMessageId] = useState<string>();
  const [termsConditon, setTermsConditon] = useState<boolean | null>();
  const [termsConditionData, setTermsConditionData] = useState<string>('');
  const [termsConditionID, setTermsConditionID] = useState<string>('');
  const [termsConditionDetail, setTermsConditionDetail] = useState<TermsConditionsDetailData>();
  const [firstAccess, setFirstAccess] = useState<boolean>(false);
  const [acceptedTerms, setAceptedTerms] = useState<boolean | null>(null);
  const [loadingStatus, setLoadingStatus] = useState<LoadingVariablesStatus>({
    loadingBidID: true,
    loadingStepStatus: true,
    loadingData: true,
    loadingTerms: true,
    loadingTermsStatus: false,
    loadingAcceptedTerms: false,
    loadingDeniedTerms: false,
  });
  const [isLoadingFiles, setIsLoadingFiles] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploadedFileList, setUploadedFileList] = useState<IUploadedFiles[]>([]);
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
  const [fileIdDelete, setFileIdDelete] = useState<string>('');
  const [uploadProgress, setUploadProgress] = useState<IProcessPercent>({});

  const completeSocketURL = idBid ? `${import.meta.env.VITE_API_WEBSOCKET}?channel=${idBid}` : null;

  const { sendJsonMessage, lastMessage, lastJsonMessage } = useWebSocket(
    completeSocketURL,
    {
      shouldReconnect: () => true,
      retryOnError: true,
    },
    !!completeSocketURL
  );

  const closeWebSocketConnection = (channel: string) => {
    sendJsonMessage({ action: 'unsubscribe', channel });
  };

  const formatIdOptions = (data: Array<any>) => {
    if (data.length > 0) {
      const formattedData: StyledSelectOptions[] = data.map((item) => {
        return {
          label: item.bidName,
          value: item.id,
        };
      });

      return formattedData;
    }
    return [];
  };

  const updateLoadingStatus = (
    loadingVariable: keyof LoadingVariablesStatus,
    newStatus: boolean
  ) => {
    setLoadingStatus((prevState) => ({
      ...prevState,
      [loadingVariable]: newStatus,
    }));
  };

  const getBidId = async () => {
    updateLoadingStatus('loadingBidID', true);
    try {
      const { data, status } = await api.get(
        getURI(`${services.rfq}/bid/filter`, {
          supplier: getSupplierUserName()?.supplier || '',
          allowSupplierView: true,
        })
      );
      if (status === 200) {
        setBidOption(formatIdOptions(data?.content));

        setIdBid(state ? state.bidId : data?.content[0].id);
      }
      return;
    } catch (error) {
      oneAlert({
        type: 'error',
        message: t('toast.errorOnList'),
      });
    } finally {
      updateLoadingStatus('loadingBidID', false);
    }
  };

  const updateStatus = (data: ITimeLine[]) => {
    const stepsCopy = initialSteps;
    let currentIndex = 0;

    data.forEach((item) => {
      currentIndex = stepsCopy.findIndex((step) => step.key === item.status);
      if (currentIndex > -1) {
        stepsCopy[currentIndex].status = 'finish';
        stepsCopy[currentIndex].description = (
          <div>
            <span>
              {dayjs(item.dateTime).format('MM.DD.YYYY')} - {dayjs(item.dateTime).format('HH:mm')}
            </span>
          </div>
        );
      }
    });
    if (data.some((item) => item.status === 'CANCELED')) {
      const lastItem = data[data.length - 1];
      const cancelStatus = stepsCopy.filter((status) => status.status === 'finish');
      cancelStatus.push({
        title: <S.StatusTitle>{t('pages.viewRfq.steps.cancel')}</S.StatusTitle>,
        icon: <S.StatusIcons />,
        key: BidStatus.CANCELED,
        status: 'finish',
        description: (
          <div>
            <span>
              {dayjs(lastItem.dateTime).format('MM.DD.YYYY')} -{' '}
              {dayjs(lastItem.dateTime).format('HH:mm')}
            </span>
          </div>
        ),
      });
      return setSteps(cancelStatus);
    }

    setSteps(stepsCopy);
  };

  const fetchStatus = async () => {
    if (!idBid) return;
    updateLoadingStatus('loadingStepStatus', true);
    try {
      const { data, status }: { data: Array<ITimeLine>; status: HttpStatusCode } = await api.patch(
        getURI(`${services.rfq}/bid/status`, {
          idBid,
        })
      );

      if (status === 200) {
        updateStatus(data);
      }
      return;
    } catch (error) {
      oneAlert({
        type: 'error',
        message: t('toast.errorOnList'),
      });
    } finally {
      updateLoadingStatus('loadingStepStatus', false);
    }
  };

  const fetchData = async () => {
    if (!idBid) return;
    updateLoadingStatus('loadingData', true);
    try {
      const { data, status }: { data: BidSupplierViewData; status: HttpStatusCode } = await api.get(
        getURI(`${services.rfq}/bid/${idBid}/supplier`)
      );

      if (status === 200) {
        setBidData(data);
      }
      return;
    } catch (error) {
      oneAlert({
        type: 'error',
        message: t('toast.errorOnList'),
      });
    } finally {
      updateLoadingStatus('loadingData', false);
    }
  };

  const fetchTermsCondition = async () => {
    if (!idBid) return;
    updateLoadingStatus('loadingTerms', true);
    try {
      const { data, status }: { data: TermsConditionsData; status: HttpStatusCode } = await api.get(
        getURI(`${services.rfq}/bid/${idBid}/termsAndConditions`)
      );

      if (status === 200) {
        setTermsConditionID(data.id);
        setTermsConditionData(data.termsAndConditions);
      }
      if (status === 204) {
        setTermsConditon(false);
        setTermsConditionID('');
        setTermsConditionData('');
        setAceptedTerms(null);
        setTermsConditionDetail(undefined);
      }
    } catch (error) {
      oneAlert({
        type: 'error',
        message: t('toast.errorOnList'),
      });
    } finally {
      updateLoadingStatus('loadingTerms', false);
    }
  };

  const fetchTermsConditionStatus = async () => {
    if (!termsConditionID) return;
    updateLoadingStatus('loadingTermsStatus', true);
    try {
      const { data, status }: { data: TermsConditionsDetailData; status: HttpStatusCode } =
        await api.get(
          getURI(`${services.rfq}/bid/termsAndConditions/${termsConditionID}/termDetail/`, {
            supplier: getSupplierUserName()?.supplier,
          })
        );

      if (status === 200) {
        setTermsConditionDetail(data);
        setAceptedTerms(data.acceptedTermsAndConditions);
        setFirstAccess(false);
        if (data.acceptedTermsAndConditions) {
          setTermsConditon(true);
        } else setTermsConditon(false);
      }
      if (status === 204) {
        setAceptedTerms(null);
        setTermsConditionDetail(undefined);
        setTermsConditon(false);
        setFirstAccess(true);
      }
    } catch (error) {
      oneAlert({
        type: 'error',
        message: t('toast.errorOnList'),
      });
    } finally {
      updateLoadingStatus('loadingTermsStatus', false);
    }
  };

  const acceptTerms = async () => {
    updateLoadingStatus('loadingAcceptedTerms', true);
    try {
      const { status }: { data: TermsConditionsDetailData; status: HttpStatusCode } = await api.put(
        getURI(`${services.rfq}/bid/${idBid}/termsAndConditions`),
        {
          acceptedTermsAndConditions: true,
          acceptDate: dayjs().toISOString(),
          idBid,
          idTermsAndConditions: termsConditionID,
          userAccept: getSupplierUserName()?.user,
          supplier: getSupplierUserName()?.supplier,
          viewedTermsAndConditions: true,
        }
      );

      if (status === 200) {
        setTermsConditon(false);
        setFirstAccess(true);
        fetchTermsConditionStatus();
      }
    } catch (error) {
      oneAlert({
        type: 'error',
        message: t('toast.errorOnList'),
      });
    } finally {
      updateLoadingStatus('loadingAcceptedTerms', false);
    }
  };

  const denyTerms = async () => {
    updateLoadingStatus('loadingDeniedTerms', true);
    try {
      const { status }: { data: TermsConditionsDetailData; status: HttpStatusCode } = await api.put(
        getURI(`${services.rfq}/bid/${idBid}/termsAndConditions`),
        {
          acceptedTermsAndConditions: false,
          rejectDate: dayjs().toISOString(),
          idBid,
          idTermsAndConditions: termsConditionID,
          userReject: getSupplierUserName()?.user,
          supplier: getSupplierUserName()?.supplier,
          viewedTermsAndConditions: true,
        }
      );

      if (status === 200) {
        setTermsConditon(false);
        setFirstAccess(true);
        fetchTermsConditionStatus();
      }
    } catch (error) {
      oneAlert({
        type: 'error',
        message: t('toast.errorOnList'),
      });
    } finally {
      updateLoadingStatus('loadingDeniedTerms', false);
    }
  };
  const Review = () => {
    setFirstAccess(true);
  };

  const validateIsPdf = (fileType: string | undefined) => {
    if (fileType !== 'application/pdf') {
      return false;
    }
    return true;
  };

  const validateSize = (size: number) => {
    if (size / 1024 / 1024 > 10) {
      return false;
    }
    return true;
  };

  const validateRestrictions = (file: UploadFile) => {
    const isPdf = validateIsPdf(file.type);
    const validSize = validateSize(file.size || 0);

    if (isPdf && validSize) {
      return 'uploading';
    }
    if (!isPdf && !validSize) {
      return 'formatErrorSizeError';
    }
    if (!isPdf) {
      return 'formatError';
    }
    if (!validSize) {
      return 'sizeError';
    }
  };

  const handleUploadFile: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    const listWithResponse = newFileList.map((item) => {
      return {
        ...item,
        response: validateRestrictions(item),
      };
    });
    setFileList(listWithResponse);
  };

  const fetchUploadedFiles = async () => {
    setIsLoadingFiles(true);
    try {
      const { status, data } = await api.get(
        `${services.rfq}/filedownload/bid/${idBid}/contextUpload/ViewSupplier`
      );
      if (status === 200) {
        setUploadedFileList(data);
      } else if (status === 204) {
        setUploadedFileList([]);
      }
    } catch (error: any) {
      oneAlert({ type: 'error', message: error.message });
    } finally {
      setIsLoadingFiles(false);
    }
  };

  const deleteUploadedFile = async (fileId: string) => {
    try {
      setFileIdDelete(fileId);
      const username = getSupplierUserName()?.user;
      const { status } = await api.delete(
        `${services.rfq}/fileupload/${fileId}/bid/${idBid}/userName/${username}`
      );
      if (status === 200) {
        fetchUploadedFiles();
      }
    } catch (error: any) {
      oneAlert({ type: 'error', message: error.message });
    } finally {
      setFileIdDelete('');
    }
  };

  const removeFile = (fileId: string, status: UploadFileStatus) => {
    if (status === 'done') {
      deleteUploadedFile(fileId);
    } else {
      const filteredFiles = fileList?.filter((file) => file.uid !== fileId);
      setFileList(filteredFiles);
    }
  };

  const clearFiles = () => {
    setFileList([]);
  };

  const addProcessingError = (fileid: string) => {
    const updatedFileList = fileList.map((item) => {
      if (item.uid === fileid) {
        return { ...item, response: 'processingError' };
      }
      return item;
    });
    setFileList(updatedFileList);
  };

  const updateFilesState = (files: IExtendedUploadFiles[]) => {
    let copyFiles = fileList;

    files.forEach((item) => {
      if (item.response === 'done') {
        copyFiles = copyFiles?.filter((file) => file.uid !== item.uid);
      } else {
        addProcessingError(item.uid);
      }
    });
    setFileList(copyFiles);
    fetchUploadedFiles();
  };

  const uploadFileWithProgress = async (
    file: IExtendedUploadFiles
  ): Promise<AxiosResponse<IUploadedFiles>> => {
    const formData = new FormData();

    formData.append('bid', idBid);
    formData.append('file', file.originFileObj as File);
    formData.append('supplier', getSupplierUserName()?.supplier.trim());
    formData.append('userName', getSupplierUserName()?.user.trim());
    formData.append('contextUpload', 'ViewSupplier');

    const response = await api.post(`${services.rfq}/fileupload`, formData, {
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress((prev) => ({
            ...prev,
            [file.uid]: percentCompleted,
          }));
        }
      },
    });

    return response;
  };

  const sendFiles = async () => {
    const filesToUpload: IExtendedUploadFiles[] = fileList.filter(
      (file) => file.response === 'uploading' || file.response === 'processingError'
    );
    if (filesToUpload?.length > 0) {
      setLoadingUpdate(true);
      await Promise.all(
        filesToUpload?.map(async (file) => {
          try {
            const { status } = await uploadFileWithProgress(file);

            if (status === 201 || status === 200) {
              file.response = 'done';
              oneAlert({
                type: 'success',
                message: t('pages.bidSupplierRFQ.quotation.modal.successfuly'),
              });
            } else {
              file.response = 'processingError';
            }
          } catch (e) {
            file.response = 'processingError';
          }
        })
      );
      setLoadingUpdate(false);
    }
    updateFilesState(filesToUpload);
  };

  const fecthAwards = async () => {
    try {
      setIsLoading(true);
      const { status, data } = await api.get(`${services.rfq}/award-received/${idBid}`);
      if (status === 200 && data.message) {
        setAwardData(data);
        if (!data.messageAccept) {
          setIsAwardModalOpen(true);
          setMessageId(data.id);
        }
        fetchUploadedFiles();
      } else setAwardData(undefined);
    } catch (error: any) {
      setAwardData(undefined);
      oneAlert({ type: 'error', message: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  const acceptAwardMessage = async () => {
    try {
      setIsLoadingMessage(true);
      const { status } = await api.put(`${services.rfq}/award-received/${messageId}/accept`);
      if (status === 200 || status === 204) {
        setIsAwardModalOpen(false);
      }
    } catch (error: any) {
      oneAlert({ type: 'error', message: error.message });
    } finally {
      setIsLoadingMessage(false);
    }
  };

  useEffect(() => {
    fetchTermsCondition();
  }, [idBid]);

  useEffect(() => {
    fetchTermsConditionStatus();
  }, [termsConditionID]);

  useEffect(() => {
    Object.values(loadingStatus).some((item) => item === true)
      ? setIsLoading(true)
      : setIsLoading(false);
  }, [loadingStatus]);

  const verifyAltGroups = (updatedAltGroup: string) => {
    const altGroups = bidData.itemsQuotation.flatMap((bidCard) =>
      bidCard.items.map((item) => item.name)
    );
    if (altGroups.includes(updatedAltGroup) && acceptedTerms) {
      fetchData();
    }
  };

  useEffect(() => {
    if (lastMessage !== null || (lastJsonMessage as { altGroup: any }) !== null) {
      verifyAltGroups((lastJsonMessage as { altGroup: any }).altGroup);
    }
  }, [lastMessage, lastJsonMessage]);

  const onNavigateOut = history.listen((location) => {
    if (location.pathname !== pathname) {
      closeWebSocketConnection(idBid);
    }
  });

  useEffect(() => {
    if (steps.find((step) => step.key === BidStatus.RESULT)?.status === 'finish' && acceptedTerms) {
      fecthAwards();
    }
  }, [steps, acceptedTerms]);

  useEffect(() => {
    return () => {
      onNavigateOut();
    };
  }, []);

  return {
    steps,
    bidData,
    bidOptions,
    idBid,
    loading,
    termsConditon,
    openModal,
    termsConditionData,
    termsConditionID,
    firstAccess,
    termsConditionDetail,
    acceptedTerms,
    fetchTermsCondition,
    acceptTerms,
    denyTerms,
    fetchStatus,
    updateStatus,
    getBidId,
    fetchData,
    setIdBid,
    getSupplierUserName,
    setOpenModal,
    Review,
    openUploadModal,
    openUploadedFilesModal,
    setOpenUploadModal,
    setOpenUploadedFilesModal,
    fileList,
    removeFile,
    handleUploadFile,
    clearFiles,
    sendFiles,
    uploadedFileList,
    uploadFileWithProgress,
    uploadProgress,
    loadingUpdate,
    fetchUploadedFiles,
    isLoadingFiles,
    fileIdDelete,
    isAwardModalOpen,
    setIsAwardModalOpen,
    fecthAwards,
    awardData,
    isLoadingMessage,
    setAceptedTerms,
    acceptAwardMessage,
    setAwardData,
    setTermsConditon,
  };
};
