import { SearchOutlined } from '@ant-design/icons';
import { Empty } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import StyledButton from '../../../components/Common/StyledButton';
import StyledDatePicker from '../../../components/Common/StyledDatePicker';
import StyledSelect from '../../../components/Common/StyledSelect';
import { Loading } from '../../../components/Loading';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import theme from '../../../styles/theme';
import * as S from './styles';
import { useInventory } from './useInventory';

export function InventoryPage() {
  const {
    isLoading,
    params,
    CMs,
    Sources,
    categories,
    partNumbers,
    products,
    specifications,
    suppliers,
    inventoryComponents,
    handleFetchHeaders,
    handleUpdateFilters,
    handleRenderClearAllFilters,
    handleRenderFiltersTags,
    handleRenderTablesComponents,
    handleFetchComponents,
    disabledDate,
    handleFetchLastDate,
    isFirstRendering,
  } = useInventory();
  const { t } = useTranslation();
  useDocumentTitle(t('pages.inventory.title'));

  useEffect(() => {
    if (!isFirstRendering) {
      handleFetchHeaders();
      handleFetchComponents();
    }
  }, [isFirstRendering]);

  useEffect(() => {
    !isFirstRendering && handleFetchHeaders();
  }, [params]);

  useEffect(() => {
    handleFetchLastDate();
  }, []);

  return (
    <S.Container>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <S.ContainerFilters>
            <StyledSelect
              options={products}
              label={t('pages.inventory.labels.product')}
              placeholder={t('pages.inventory.placeholders.product')}
              onChange={(value: string) => {
                handleUpdateFilters('product', value);
              }}
              styles={{ minWidth: '7.25rem' }}
              value={params.product}
            />
            <StyledSelect
              label={t('pages.inventory.labels.cm')}
              placeholder={t('pages.inventory.placeholders.cm')}
              onChange={(value: string) => {
                handleUpdateFilters('cm', value);
              }}
              options={CMs}
              value={params.cm === '' ? 'Show All' : params.cm}
              styles={{ minWidth: '7.25rem' }}
            />

            <StyledSelect
              options={categories}
              suffixIcon={<SearchOutlined style={{ color: theme.colors.dark_green }} />}
              showSearch
              label={t('pages.inventory.labels.category')}
              placeholder={t('pages.inventory.placeholders.category')}
              onChange={(value: string) => {
                handleUpdateFilters('category', value);
              }}
              styles={{ minWidth: '7.25rem' }}
              value={params.category || null}
            />

            <StyledSelect
              options={specifications}
              showSearch
              suffixIcon={<SearchOutlined style={{ color: theme.colors.dark_green }} />}
              label={t('pages.inventory.labels.specification')}
              placeholder={t('pages.inventory.placeholders.specification')}
              onChange={(value: any) => {
                handleUpdateFilters('spec', value);
              }}
              styles={{ minWidth: '8.25rem' }}
              value={params.spec || null}
            />
            <StyledSelect
              label={t('pages.inventory.labels.partNumber')}
              suffixIcon={<SearchOutlined style={{ color: theme.colors.dark_green }} />}
              placeholder={t('pages.inventory.placeholders.partNumber')}
              onChange={(value: any) => {
                handleUpdateFilters('partNumber', value);
              }}
              options={partNumbers}
              showSearch
              value={params.partNumber || null}
              styles={{ minWidth: '8.75rem' }}
            />
            <StyledSelect
              label={t('pages.inventory.labels.source')}
              placeholder={t('pages.inventory.placeholders.source')}
              onChange={(value: any) => {
                handleUpdateFilters('source', value);
              }}
              options={Sources}
              value={params.source || null}
              styles={{ minWidth: '7.25rem' }}
            />
            <StyledSelect
              suffixIcon={<SearchOutlined style={{ color: theme.colors.dark_green }} />}
              label={t('pages.inventory.labels.supplier')}
              placeholder={t('pages.inventory.placeholders.supplier')}
              onChange={(value: string) => {
                handleUpdateFilters('supplier', value);
              }}
              options={suppliers}
              showSearch
              value={params.supplier || null}
              styles={{ minWidth: '7.25rem' }}
            />
            <StyledDatePicker
              label={t('pages.inventory.labels.date')}
              onChange={(date, _) => {
                handleUpdateFilters('date', date);
              }}
              defaultValue={params.date}
              styles={{ minWidth: '7.25rem' }}
              disableDate={disabledDate}
              format={t('common.dateFormat')}
            />
            <StyledButton
              small
              onClick={() => {
                handleFetchComponents();
              }}
              variant="primary"
              style={{ height: '2rem' }}
              text={t('pages.inventory.labels.apply')}
            />
          </S.ContainerFilters>
          <S.FiltersTagContainer>
            {handleRenderFiltersTags()}
            {handleRenderClearAllFilters()}
          </S.FiltersTagContainer>
          {inventoryComponents.length > 0 && handleRenderTablesComponents()}
          {!inventoryComponents.length && <Empty />}
        </>
      )}
    </S.Container>
  );
}
