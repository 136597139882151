import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.header};

  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  border-radius: 0.5rem;
  width: 100%;
  gap: 1rem;
`;

export const ContainerSupplier = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  border-right: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  padding: 0.5rem;

  p {
    margin: 0;
  }
`;

export const ContainerData = styled.div`
  display: flex;
  width: 70%;
  gap: 1.5rem;
  align-items: center;
  justify-content: flex-end;
`;

export const Title = styled.p`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
  margin: 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 0.4rem;

  p {
    margin: 0;
  }
`;

export const Labels = styled.p<{ positive?: boolean }>`
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
  font-size: 0.75rem;
  line-height: 1.25rem;

  &.colored {
    font-size: 0.875rem;
    font-weight: bold;
    color: ${({ theme, positive }) =>
      positive
        ? theme.colorsDesignSystem.red
        : theme.colorsDesignSystem.rfqIndicationTagColors.greenFont};
  }

  &.pipe {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colorsDesignSystem.grayBorderTooltip};
  }

  &.value {
    font-size: 1rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
  }

  &.valueLarge {
    font-size: 1.5rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
  }

  &.title {
    color: ${({ theme }) => theme.colorsDesignSystem.text};
    font-size: 1.25rem;
    font-weight: bold;
  }
`;
