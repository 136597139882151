import styled from 'styled-components';
import { CountDownProps } from './types';

export const Container = styled.main<CountDownProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ showDate }) => (showDate ? 'space-between' : null)};
  align-items: center;
  gap: 2rem;
`;

export const ContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  span {
    font-size: 0.625rem;
    color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
  }

  b {
    font-weight: 600;
    font-size: 1rem;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
  }
`;

export const ContainerRight = styled.div`
  display: flex;
  flex-direction: column;
`;
export const CountdownTitle = styled.span`
  font-size: 0.625rem;
  color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
  margin-bottom: 0 !important;
  padding-bottom: 0;
`;

export const UTCContainer = styled.span`
  font-size: 0.625rem;
  color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
  padding: 0;
  margin: 0;
`;
