import { Flex, Tooltip } from 'antd';
import { t } from 'i18next';
import { handleFormatValueFields } from '../../../helpers/nUtils';
import * as S from './styles';
import { BidSuppliers } from './types';

export function CardSuppliers({ demand, impact, saving, supplier, totalWin }: BidSuppliers) {
  return (
    <S.Container>
      <S.ContainerSupplier>
        <S.Labels className="title">{supplier}</S.Labels>
      </S.ContainerSupplier>
      <S.ContainerData>
        <S.Content>
          <S.Labels>{t('components.cardAwardBidSupplier.labels.impact')}</S.Labels>
          <Flex gap={8} align="center">
            <S.Labels>
              {impact
                ? `${handleFormatValueFields({
                    number: impact,
                    notation: 'standard',
                  })}%`
                : '-'}
            </S.Labels>
            <S.Labels className="pipe">|</S.Labels>
            <Tooltip
              title={handleFormatValueFields({
                number: saving,
              })}
            >
              <S.Labels className="colored">
                ${' '}
                {handleFormatValueFields({
                  number: saving,
                  notation: 'compact',
                  minFractional: 1,
                  maxFactional: 2,
                })}
              </S.Labels>
            </Tooltip>
          </Flex>
        </S.Content>
        <S.Content>
          <S.Labels>{t('components.cardAwardBidSupplier.labels.demand')}</S.Labels>
          <Flex gap={8} align="center">
            <S.Labels className="value">
              {demand ? (
                <Tooltip
                  title={handleFormatValueFields({
                    number: demand,
                    maxFactional: 0,
                    minFractional: 0,
                  })}
                >
                  {handleFormatValueFields({
                    number: demand,
                    notation: 'compact',
                    minFractional: 1,
                    maxFactional: 2,
                  })}
                </Tooltip>
              ) : (
                '-'
              )}
            </S.Labels>
          </Flex>
        </S.Content>
        <S.Content>
          <S.Labels>{t('components.cardAwardBidSupplier.labels.total')}</S.Labels>
          <Flex gap={8} align="center">
            <S.Labels className="valueLarge">
              {totalWin ? (
                <Tooltip
                  title={handleFormatValueFields({
                    number: totalWin,
                  })}
                >
                  ${' '}
                  {handleFormatValueFields({
                    number: totalWin,
                    notation: 'compact',
                    minFractional: 1,
                    maxFactional: 2,
                  })}
                </Tooltip>
              ) : (
                '-'
              )}
            </S.Labels>
          </Flex>
        </S.Content>
      </S.ContainerData>
    </S.Container>
  );
}
