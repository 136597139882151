import { FieldTimeOutlined } from '@ant-design/icons';
import { Flex, Row, Statistic } from 'antd';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useEffect } from 'react';
import theme from '../../../styles/theme';
import * as S from './styles';
import { CardCountDownProps } from './types';
import { useCardCountDown } from './useCardCountDown';

export default function CardCountDown({
  endDate,
  showEndDate = true,
  showTime = false,
  stop = false,
  onFinished,
}: CardCountDownProps) {
  const { Countdown } = Statistic;
  const { statusUrgency, color } = useCardCountDown();

  useEffect(() => {
    if (dayjs(endDate) < dayjs()) {
      stop = true;
      endDate = dayjs().format();
      return;
    }
    stop && (endDate = dayjs().format());
  }, []);

  return (
    <S.Container>
      {showEndDate && (
        <S.ContainerLeft>
          <Flex vertical gap={2} justify="flex-start">
            <span>{t('components.CardCountDown.respond').toUpperCase()}</span>
            <b>{dayjs(endDate).format('DD/MM/YYYY')}</b>
            {showTime && <b>{dayjs(endDate).format('HH:mm:ss')}</b>}
          </Flex>
        </S.ContainerLeft>
      )}
      <S.ContainerRight>
        <Flex gap={8}>
          <Flex vertical gap={1}>
            <Row>
              <Countdown
                value={endDate}
                format="DDd HH:mm:ss"
                onFinish={onFinished}
                title={
                  <S.CountdownTitle>
                    {t('components.CardCountDown.timeClose').toUpperCase()}
                  </S.CountdownTitle>
                }
                prefix={
                  <FieldTimeOutlined
                    style={{ fontSize: 26, color: `${theme.colorsDesignSystem.grayAcer}` }}
                  />
                }
                valueStyle={{
                  fontSize: 26,
                  color: `${color}`,
                  padding: '0',
                  margin: '0',
                }}
                style={{ padding: '0', margin: '0' }}
                onChange={(value) => {
                  statusUrgency(value);
                }}
              />
            </Row>
            <S.UTCContainer>UTC -3. Brasilia</S.UTCContainer>
          </Flex>
        </Flex>
      </S.ContainerRight>
    </S.Container>
  );
}
