import { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import StyledIndicationTag from '../../components/IndicationTag';
import { useNotifications } from '../../context/notification';
import { AlertNotification, QualificationAlert } from '../../context/types';
import { dateFormat, getSortOrder } from '../../helpers/utils';
import { useTable } from '../../hooks/useTable';
import { api } from '../../services/api';
import { services } from '../../services/services';
import { HeaderQualificationAlert } from './types';

const initialParams = {
  order: 'category',
  orderBy: 'ASC',
  page: 0,
  limit: 25,
};

export function useQualificationAlert() {
  const { t } = useTranslation();
  const { id: alertId } = useParams<{ id: string }>();
  const { viewNotification } = useNotifications();
  const { getSortOrderTable, getSortTitle } = useTable();
  const [headerInfo, setHeaderInfo] = useState<HeaderQualificationAlert>();
  const [tableData, setTableData] = useState<QualificationAlert[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [params, setParams] = useState(initialParams);

  const handleConvertAlertNotificationToNotificationSku = (
    alert: AlertNotification
  ): QualificationAlert[] => {
    try {
      const notifications: Array<QualificationAlert> = [];
      notifications.push({
        id: alert.id,
        category: alert.category,
        specification: alert.specification,
        partNumber: alert.partNumber,
        qStatus: alert.qstatus,
        remarks: alert.remark || '-',
        project: alert.project,
        avlc: alert.avlc,
        categoryProduct: alert.categoryProduct,
        description: alert.description,
        vendor: alert.vendor,
      });
      return notifications;
    } catch (error) {
      return [];
    }
  };

  const handleCreateHeaderInfo = (alert: AlertNotification, qtFound: string) => {
    const formatedDate = dateFormat(alert.creationDateTime);
    setHeaderInfo({
      avlc: alert.avlc,
      date: formatedDate.date,
      time: formatedDate.time,
      qtFound,
      type: alert.category,
    });
  };

  const fetchAlert = async () => {
    const { data: response }: { data: AlertNotification } = await api.get(
      `${services.events}/list/alerts/${alertId}`
    );
    const skus = handleConvertAlertNotificationToNotificationSku(response);
    setTableData(skus);
    handleCreateHeaderInfo(response, skus.length.toString());
    viewNotification(alertId);
    setIsLoading(false);
  };

  const handleChangeTable = (pagination: any, filters: any, sorter: any) => {
    setParams({
      ...params,
      order: sorter.columnKey,
      orderBy: getSortOrder(sorter.order),
      page: 0,
    });
  };

  const columns: ColumnsType<QualificationAlert> = [
    {
      title: t('pages.qualificationAlert.fields.project'),
      dataIndex: 'project',
      key: 'project',
      width: 150,
      sorter: (line1: QualificationAlert, line2: QualificationAlert) => {
        return line1.project.localeCompare(line2.project);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: params.order === 'project' && getSortOrderTable(params.orderBy),
      showSorterTooltip: { title: getSortTitle(params.orderBy, params.order, 'project', false) },
    },
    {
      title: t('pages.qualificationAlert.fields.category'),
      dataIndex: 'categoryProduct',
      key: 'categoryProduct',
      width: 150,
      defaultSortOrder: 'ascend',
      sorter: (line1: QualificationAlert, line2: QualificationAlert) => {
        return line1.category.localeCompare(line2.category);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: params.order === 'categoryProduct' && getSortOrderTable(params.orderBy),
      showSorterTooltip: {
        title: getSortTitle(params.orderBy, params.order, 'categoryProduct', false),
      },
    },
    {
      title: t('pages.qualificationAlert.fields.specification'),
      dataIndex: 'specification',
      key: 'specification',
      sorter: (line1: QualificationAlert, line2: QualificationAlert) => {
        return line1.specification.localeCompare(line2.specification);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: params.order === 'specification' && getSortOrderTable(params.orderBy),
      showSorterTooltip: {
        title: getSortTitle(params.orderBy, params.order, 'specification', false),
      },
    },
    {
      title: t('pages.qualificationAlert.fields.partNumber'),
      dataIndex: 'partNumber',
      key: 'partNumber',
      width: 150,
      sorter: (line1: QualificationAlert, line2: QualificationAlert) => {
        return line1.partNumber.localeCompare(line2.partNumber);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: params.order === 'partNumber' && getSortOrderTable(params.orderBy),
      showSorterTooltip: { title: getSortTitle(params.orderBy, params.order, 'partNumber', false) },
    },
    {
      title: t('pages.qualificationAlert.fields.qSatus'),
      dataIndex: 'qStatus',
      width: 150,
      render: (_, record) => {
        return <StyledIndicationTag text={record.qStatus ? record.qStatus.toUpperCase() : '-'} />;
      },
      key: 'qStatus',
      sorter: (line1: QualificationAlert, line2: QualificationAlert) => {
        return line1.qStatus.localeCompare(line2.qStatus);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: params.order === 'qStatus' && getSortOrderTable(params.orderBy),
      showSorterTooltip: { title: getSortTitle(params.orderBy, params.order, 'qStatus', false) },
    },
    {
      title: t('pages.qualificationAlert.fields.remarks'),
      dataIndex: 'remarks',
      key: 'remarks',
      sorter: (line1: QualificationAlert, line2: QualificationAlert) => {
        return line1?.remarks.localeCompare(line2?.remarks);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: params.order === 'remarks' && getSortOrderTable(params.orderBy),
      showSorterTooltip: { title: getSortTitle(params.orderBy, params.order, 'remarks', false) },
    },
    {
      title: t('pages.qualificationAlert.fields.description'),
      dataIndex: 'description',
      key: 'description',
      sorter: (line1: QualificationAlert, line2: QualificationAlert) => {
        return line1?.description.localeCompare(line2?.description);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: params.order === 'remarks' && getSortOrderTable(params.orderBy),
      showSorterTooltip: { title: getSortTitle(params.orderBy, params.order, 'remarks', false) },
    },
    {
      title: t('pages.qualificationAlert.fields.supplier'),
      dataIndex: 'vendor',
      key: 'vendor',
      sorter: (line1: QualificationAlert, line2: QualificationAlert) => {
        return line1?.vendor.localeCompare(line2?.vendor);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: params.order === 'remarks' && getSortOrderTable(params.orderBy),
      showSorterTooltip: { title: getSortTitle(params.orderBy, params.order, 'remarks', false) },
    },
  ];

  return {
    isLoading,
    columns,
    tableData,
    params,
    headerInfo,
    fetchAlert,
    handleChangeTable,
  };
}
