import { CheckCircleOutlined } from '@ant-design/icons';
import { Flex, Tooltip } from 'antd';
import { t } from 'i18next';
import { ReactNode, useState } from 'react';
import { handleFormatValueFields, handleVerifyValueIsPositive } from '../../../helpers/nUtils';
import theme from '../../../styles/theme';
import { AwardSupplierRanking, CommentAward, PartNumberAward } from '../types';
import * as S from './styles';
import { IRenderVariance } from './types';

export function useSupplierCard() {
  const [visibleModal, setVisibleModal] = useState(false);

  const renderVariance = (props: IRenderVariance) => {
    return (
      <Flex gap={8} align="baseline">
        <S.Labels className="small">
          {props.variancePercentage
            ? `+ ${handleFormatValueFields({
                number: props.variancePercentage,
                notation: 'standard',
              })}%`
            : props.position > 1
            ? '-'
            : null}
        </S.Labels>
        <S.LabelColored color="red" size="normal">
          {props.variance
            ? `+ $ ${handleFormatValueFields({
                number: props.variance,
                notation: 'standard',
              })}`
            : props.position > 1
            ? '-'
            : null}
        </S.LabelColored>
      </Flex>
    );
  };

  const handleRenderVariancePercentage = (
    grossVisualization: boolean,
    data: AwardSupplierRanking
  ) => {
    if (grossVisualization)
      return renderVariance({
        position: data.position,
        variance: data.variance,
        variancePercentage: data.variancePercentage,
      });
    return renderVariance({
      position: data.position,
      variance: data.varianceNet,
      variancePercentage: data.variancePercentageNet,
    });
  };

  const handleRenderPartNumbers = (partNumbers: Array<PartNumberAward>): ReactNode => {
    try {
      return partNumbers.map((partNumber) => {
        return (
          <S.ContainerRows key={partNumber.partNumber}>
            <S.Labels className="small">{t('pages.awardSplit.awardCard.partNumber')}</S.Labels>
            <Flex gap={4}>
              <CheckCircleOutlined
                style={{ fontSize: '0.75rem', color: theme.colorsDesignSystem.primary }}
              />
              <S.Labels className="small">{partNumber.qstatus || '-'}</S.Labels>
              <S.Labels className="smallBold">{partNumber.partNumber || '-'}</S.Labels>
            </Flex>
          </S.ContainerRows>
        );
      });
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const handleConvertCommentAwardToComment = (
    comments: Array<CommentAward>,
    data: AwardSupplierRanking
  ) => {
    return comments.map((comment) => {
      return {
        name: comment.name,
        comment: comment.comment,
        supplier: data.supplier,
        dateTime: comment.dateTime,
      };
    });
  };

  const handleCalculateTotalNetGrossBaseline = (demand: number, price: number) => {
    try {
      return demand * price;
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  const handleCalculateImpactSavingPercentage = (
    impactSaving: number,
    totalBaseline: number
  ): number => {
    try {
      return (impactSaving / totalBaseline) * 100;
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  const handleRenderFieldByCalculatedValue = <T extends keyof AwardSupplierRanking>(
    supplier: AwardSupplierRanking,
    field: T
  ): ReactNode => {
    if (field === 'totalNet') {
      const value = handleCalculateTotalNetGrossBaseline(supplier.demand, supplier.netPrice);
      return (
        <S.Labels className="middle">
          {value ? (
            <Tooltip
              title={handleFormatValueFields({
                number: value,
              })}
            >
              $
              {handleFormatValueFields({
                number: value,
                notation: 'compact',
              })}
            </Tooltip>
          ) : (
            '-'
          )}
        </S.Labels>
      );
    }

    if (field === 'totalGross') {
      const value = handleCalculateTotalNetGrossBaseline(supplier.demand, supplier.grossPrice);
      return (
        <S.Labels className="normal">
          {value ? (
            <Tooltip
              title={handleFormatValueFields({
                number: value,
              })}
            >
              $
              {handleFormatValueFields({
                number: value,
                notation: 'compact',
              })}
            </Tooltip>
          ) : (
            '-'
          )}
        </S.Labels>
      );
    }

    if (field === 'totalBaseline') {
      const value = handleCalculateTotalNetGrossBaseline(supplier.demand, supplier.baseline);
      return (
        <S.Labels className="smallBold">
          {value ? (
            <Tooltip
              title={handleFormatValueFields({
                number: value,
              })}
            >
              $
              {handleFormatValueFields({
                number: value,
                notation: 'compact',
              })}
            </Tooltip>
          ) : (
            '-'
          )}
        </S.Labels>
      );
    }

    if (field === 'impactSavingPercentage') {
      const totalNet = handleCalculateTotalNetGrossBaseline(supplier.demand, supplier.netPrice);
      const totalBaseline = handleCalculateTotalNetGrossBaseline(
        supplier.demand,
        supplier.baseline
      );
      const impactSaving = totalNet - totalBaseline;
      const value = handleCalculateImpactSavingPercentage(impactSaving, totalBaseline);

      return (
        <S.Labels className="small">
          {value
            ? `${handleFormatValueFields({
                number: value,
                notation: 'standard',
              })}%`
            : '-'}
        </S.Labels>
      );
    }

    if (field === 'impactSaving') {
      const totalNet = handleCalculateTotalNetGrossBaseline(supplier.demand, supplier.netPrice);
      const totalBaseline = handleCalculateTotalNetGrossBaseline(
        supplier.demand,
        supplier.baseline
      );
      const value = totalNet - totalBaseline;

      return (
        <S.LabelColored
          color={handleVerifyValueIsPositive(String(value)) ? 'red' : 'green'}
          size="strong"
        >
          {value ? (
            <Tooltip
              title={handleFormatValueFields({
                number: value,
              })}
            >
              $
              {handleFormatValueFields({
                number: value,
                notation: 'compact',
              })}
            </Tooltip>
          ) : (
            '-'
          )}
        </S.LabelColored>
      );
    }

    return null;
  };

  return {
    handleRenderPartNumbers,
    setVisibleModal,
    handleConvertCommentAwardToComment,
    handleCalculateTotalNetGrossBaseline,
    handleCalculateImpactSavingPercentage,
    handleRenderFieldByCalculatedValue,
    visibleModal,
    handleRenderVariancePercentage,
  };
}
