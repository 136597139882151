/* eslint-disable no-undefined */
import {
  ArrowLeftOutlined,
  CloseOutlined,
  DollarOutlined,
  DownOutlined,
  FileAddOutlined,
  TrophyOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Col, Divider, Dropdown, Flex, Row, Spin, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ExclamationCircle from '../../assets/exclamation-circle.svg';
import { ActionButton } from '../../components/ActionButton';
import { CommentsModal } from '../../components/Common/CommentsModal';
import { NewRoundModal } from '../../components/Common/NewRoundModal';
import StyledButton from '../../components/Common/StyledButton';
import { StyledHeader } from '../../components/Common/StyledHeaderPage';
import { StyledLoading } from '../../components/Common/StyledLoading';
import StyledModal from '../../components/Common/StyledModal';
import { BidStatus } from '../../helpers/enums';
import { getUserNameAndEmail, handleFormatValueFields } from '../../helpers/nUtils';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { CancelBidModal } from './components/cancelBidModal';
import { SupplierResponseCard } from './components/SupplierResponseCard';
import { VerticalCard } from './components/VerticalCard';
import * as S from './styles';
import { Summary } from './Summary';
import { useViewRFQ } from './useViewRFQ';

export function ViewRFQ() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.viewRfq.title'));
  const {
    bidId,
    steps,
    loading,
    bidInformations,
    isOpenCommentsModal,
    selectedAltGroup,
    tabProjects,
    altGroupDetail,
    isLoadingDetails,
    selectedProject,
    isOpenCancelBidModal,
    setIsOpenCancelBidModal,
    setSelectedProject,
    setIsOpenCommentsModal,
    handleSelectAltGroup,
    toggleOptions,
    onChangeOption,
    selectedOption,
    optionChanged,
    setOptionChanged,
    handleCancelBid,
    handleNavigateAwards,
    createNewRound,
    setIsOpenNewRoundModal,
    isOpenNewRoundModal,
    suppliers,
    fetchStatus,
  } = useViewRFQ();
  const history = useHistory();

  const enableAwardButton = steps.some(
    (step) => step.key === BidStatus.CLOSED && step.status === 'finish'
  );

  const disableNewRoundButton = steps.some(
    (step) => step.key === BidStatus.RESULT && step.status === 'finish'
  );

  const actions: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <ActionButton
          buttonText={t('pages.viewRfq.actionButton.cancelBid')}
          key={1}
          onClick={() => setIsOpenCancelBidModal(true)}
          disabled={
            steps.some((step) => step.key === BidStatus.RESULT && step.status === 'finish') ||
            steps.some((step) => step.key === BidStatus.CANCELED && step.status === 'finish')
          }
          icon={<CloseOutlined />}
        />
      ),
    },
    {
      key: '2',
      label: (
        <ActionButton
          buttonText="Awards"
          key={2}
          onClick={handleNavigateAwards}
          disabled={!enableAwardButton || !bidInformations?.validBid}
          icon={<TrophyOutlined />}
        />
      ),
    },
    {
      key: '3',
      label: (
        <ActionButton
          buttonText={t('pages.rfq.actions.newRound')}
          key={3}
          onClick={() =>
            setIsOpenNewRoundModal({
              bidId,
              isOpen: true,
            })
          }
          disabled={
            !enableAwardButton ||
            disableNewRoundButton ||
            bidInformations?.overview.createdBy !==
              getUserNameAndEmail(localStorage.getItem('idToken'))?.name ||
            bidInformations?.hasChild
          }
          icon={<FileAddOutlined />}
        />
      ),
    },
  ];

  return (
    <>
      {isOpenCancelBidModal && (
        <StyledModal
          title={t('pages.viewRfq.modalCancellationBid.modalTitle')}
          body={
            <CancelBidModal
              handleCancelBid={(message: string) => handleCancelBid(message)}
              onCancel={() => setIsOpenCancelBidModal(false)}
              closeModal={setIsOpenCancelBidModal}
            />
          }
          footer={null}
          width="45rem"
          open
          onClose={() => setIsOpenCancelBidModal(false)}
          centered
          noMargin
          noPadding
        />
      )}
      <StyledModal
        title={t('components.modals.newRound.title')}
        body={<NewRoundModal />}
        footer={
          <Flex justify="space-between" align="center">
            <Button
              type="link"
              onClick={() => setIsOpenNewRoundModal({ bidId: '', isOpen: false })}
            >
              {t('common.cancel')}
            </Button>
            <Button type="primary" onClick={createNewRound}>
              {t('common.confirm')}
            </Button>
          </Flex>
        }
        open={isOpenNewRoundModal.isOpen}
        width="44rem"
        centered
        onClose={() => setIsOpenNewRoundModal({ bidId: '', isOpen: false })}
      />
      <Col span={24}>
        {loading && <Spin fullscreen />}
        <Row>
          <S.BackButton
            type="link"
            icon={<ArrowLeftOutlined />}
            onClick={() => history.push('/rfq')}
          >
            {t('pages.viewRfq.backButton')}
          </S.BackButton>
        </Row>
        <StyledHeader
          title={t('pages.viewRfq.title')}
          actions={
            <Dropdown key={0} menu={{ items: actions }}>
              <StyledButton variant="primary">
                {t('common.action')}
                <DownOutlined />
              </StyledButton>
            </Dropdown>
          }
        />
        <Flex vertical gap={30}>
          <Summary
            steps={steps}
            summaryInformations={bidInformations?.overview}
            originalBidId={bidInformations?.originalBidId}
            round={bidInformations?.round}
            disableAward={fetchStatus}
          />
          <S.ComponentsContent>
            {bidInformations?.items.map((categories) => {
              return (
                <>
                  <S.Title>{categories.category}</S.Title>
                  {categories.altGroups.map((altGroup, index) => {
                    return (
                      <S.Content key={altGroup}>
                        <S.HeaderContent
                          isOpen={
                            selectedAltGroup.altGroup === altGroup &&
                            selectedAltGroup.category === categories.category
                          }
                          onClick={() => handleSelectAltGroup(altGroup, categories.category)}
                        >
                          <Flex gap={8}>
                            <img src={ExclamationCircle} alt="Exclamation Circle Icon" />
                            <b>{index + 1}</b>
                            <span>{altGroup}</span>
                          </Flex>
                        </S.HeaderContent>

                        {selectedAltGroup.altGroup === altGroup &&
                          selectedAltGroup.category === categories.category && (
                            <S.AltGroupContent>
                              {isLoadingDetails ? (
                                <StyledLoading height={40} />
                              ) : (
                                <>
                                  <Tabs
                                    tabBarGutter={16}
                                    items={tabProjects}
                                    animated
                                    onChange={(value) => setSelectedProject(value)}
                                    activeKey={selectedProject}
                                  />
                                  <Flex vertical gap={30}>
                                    <Flex justify="space-between" gap={16}>
                                      <S.AltGroupCard project>
                                        <S.Value fontLG>{altGroupDetail?.currentProject}</S.Value>
                                      </S.AltGroupCard>
                                      <S.AltGroupCard>
                                        <VerticalCard
                                          title={t('pages.viewRfq.openCard.specification')}
                                          value={
                                            altGroupDetail?.specifications &&
                                            altGroupDetail?.specifications.length > 0 ? (
                                              <>
                                                {altGroupDetail.specifications.map(
                                                  (spec, specIndex) => {
                                                    return (
                                                      <>
                                                        {spec}{' '}
                                                        {specIndex <
                                                          altGroupDetail.specifications.length -
                                                            1 && <Divider type="vertical" />}
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </>
                                            ) : (
                                              '-'
                                            )
                                          }
                                        />
                                        <VerticalCard
                                          title={t('pages.viewRfq.openCard.cm')}
                                          value={
                                            altGroupDetail?.cm && altGroupDetail?.cm.length > 0 ? (
                                              <>
                                                {altGroupDetail.cm.map((cmName, cmIndex) => {
                                                  return (
                                                    <>
                                                      {cmName}{' '}
                                                      {cmIndex < altGroupDetail.cm.length - 1 && (
                                                        <Divider type="vertical" />
                                                      )}
                                                    </>
                                                  );
                                                })}
                                              </>
                                            ) : (
                                              '-'
                                            )
                                          }
                                        />
                                        <VerticalCard
                                          title={t('pages.viewRfq.openCard.from')}
                                          value={altGroupDetail?.from || '-'}
                                        />
                                        <VerticalCard
                                          title={t('pages.viewRfq.openCard.to')}
                                          value={altGroupDetail?.to || '-'}
                                        />
                                      </S.AltGroupCard>
                                      <S.AltGroupCard>
                                        <VerticalCard
                                          title={
                                            <>
                                              <DollarOutlined />{' '}
                                              {t('pages.viewRfq.openCard.startingPrice')}
                                            </>
                                          }
                                          value={
                                            altGroupDetail
                                              ? `$ ${handleFormatValueFields({
                                                  number: altGroupDetail?.startingPrice,
                                                })}`
                                              : '-'
                                          }
                                        />
                                        <VerticalCard
                                          title={t('pages.viewRfq.openCard.totalDemand')}
                                          value={
                                            altGroupDetail
                                              ? ` ${handleFormatValueFields({
                                                  number: altGroupDetail?.totalDemand,
                                                  minFractional: 0,
                                                  maxFactional: 0,
                                                })}`
                                              : '-'
                                          }
                                          valueBlue
                                        />
                                      </S.AltGroupCard>
                                    </Flex>
                                    <Flex justify="flex-end">
                                      <S.StyledRadio.Group
                                        options={toggleOptions}
                                        onChange={onChangeOption}
                                        value={selectedOption}
                                        optionType="button"
                                        buttonStyle="solid"
                                      />
                                    </Flex>
                                    <S.HorizontalScroll>
                                      <Flex
                                        gap={16}
                                        style={{
                                          margin: 'auto',
                                        }}
                                      >
                                        {suppliers.map((supplier) => {
                                          return (
                                            <SupplierResponseCard
                                              key={supplier.partNumber}
                                              isNewRound={!!bidInformations.originalBidId}
                                              {...supplier}
                                              position={
                                                selectedOption === 'NET'
                                                  ? supplier.position
                                                  : supplier.positionByGross
                                              }
                                              partNumber={{
                                                partNumber: supplier.partNumber,
                                                qstatus: supplier.qstatus,
                                              }}
                                              showCommentsButton={!!supplier.comments?.length}
                                              handleDetailsModal={() =>
                                                setIsOpenCommentsModal({
                                                  isOpen: true,
                                                  comments: supplier.comments || [],
                                                  altGroup,
                                                  supplier: supplier.supplier,
                                                })
                                              }
                                              totalComments={supplier.comments?.length}
                                              optionChanged={optionChanged}
                                              setOptionChanged={() => setOptionChanged(false)}
                                              showGross={selectedOption === 'GROSS'}
                                            />
                                          );
                                        })}
                                      </Flex>
                                    </S.HorizontalScroll>
                                  </Flex>
                                </>
                              )}
                            </S.AltGroupContent>
                          )}
                      </S.Content>
                    );
                  })}
                </>
              );
            })}

            <StyledModal
              body={
                <CommentsModal
                  altGroup={isOpenCommentsModal.altGroup || ''}
                  comments={isOpenCommentsModal.comments || []}
                  supplier={isOpenCommentsModal.supplier || ''}
                />
              }
              footer={null}
              onClose={() =>
                setIsOpenCommentsModal({
                  isOpen: false,
                  comments: undefined,
                })
              }
              title={t('pages.viewRfq.openCard.comments')}
              centered
              open={isOpenCommentsModal.isOpen}
              width="54rem"
            />
          </S.ComponentsContent>
        </Flex>
      </Col>
    </>
  );
}
